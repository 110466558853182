<template>
	<div v-if="!!Object.keys(organizationRanksData).length">
		<div class="row h-100">
			<div class="col">
				<div class="card mb-0 h-100">
					<div class="card-header bg-dark">
						<h5 class="mb-0">
							{{ translate('organization_ranks') }}
						</h5>
					</div>
					<div class="card-body p-0 h-100">
						<div class="table-responsive h-100 mb-0">
							<table class="table table-hover table-striped h-100">
								<thead>
									<tr class="bg-white">
										<th class="border-top-0 align-middle text-dark">
											{{ translate('name') }}
										</th>
										<th class="border-top-0 align-middle text-dark text-center">
											{{ translate('highest') }}
										</th>
										<th class="border-top-0 align-middle text-dark text-center">
											{{ translate('current') }}
										</th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="(item, index) in Object.keys(organizationRanksData)"
										:key="index">
										<td class="align-middle">
											{{ translate(item) }}
										</td>
										<td class="align-middle text-center">
											{{ organizationRanksData[item].permanent_rank }}
										</td>
										<td class="align-middle text-center">
											{{ organizationRanksData[item].rank }}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Dashboard from '@/util/Dashboard';
import { Ranks as messages } from '@/translations';

export default {
	name: 'OrganizationRanks',
	messages,
	components: {
	},
	data() {
		return {
			organizationRanks: new Dashboard(),
		};
	},
	computed: {
		organizationRanksData() {
			try {
				const { response } = this.organizationRanks.data.response.data;
				return response;
			} catch (error) {
				return {};
			}
		},
	},
	mounted() {
		this.organizationRanks.getOrganizationRanks();
	},
};
</script>
