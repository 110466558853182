<template>
	<div class="h-100">
		<div class="row h-100">
			<div class="col mx-auto h-100">
				<div class="card rounded-0 mb-0 h-100">
					<div class="card-body rounded-0">
						<div v-show="!loading">
							<div class="row mb-2">
								<div class="col">
									<h6 class="text-capitalize float-left">
										{{ translate('week_number', { number: selectedWeek }) }}
									</h6>
									<b-button
										:variant="!showFilters ? 'primary' : 'secondary'"
										class="float-right btn btn-sm bg-primary-alt"
										:class="showFilters ? 'active' : ''"
										@click="showFilters = !showFilters">
										<i
											class="fa"
											:class="!showFilters ? 'fa-filter' : 'fa-times'"
											aria-hidden="true" />
									</b-button>
								</div>
							</div>
							<div class="row">
								<div class="col">
									<user-list
										:title="translate('income_earners')"
										:count="count"
										:users="incomeEarners" />
									<overlay-card v-show="showFilters">
										<week-filter
											form-id="income-earners-week"
											:loaded-week="loadedWeek"
											@submit="getDataFiltered"
											@clear="clear" />
									</overlay-card>
								</div>
							</div>
						</div>
						<template v-if="loading">
							<div class="h-100 d-flex justify-content-center">
								<is-loading
									class="align-self-center"
									:loading-label="translate('loading')"
									:no-data-label="translate('data_not_found')"
									:loading="loading" />
							</div>
						</template>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import OverlayCard from '@/components/OverlayCard';
import WeekFilter from '@/components/WeekFilter';
import { TopUsers } from '@/translations';
import Dashboard from '@/util/Dashboard';
import UserList from './UserList.vue';

export default {
	name: 'TopIncomeEarners',
	messages: [TopUsers],
	components: {
		OverlayCard,
		UserList,
		WeekFilter,
	},
	props: {
		count: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			selectedWeek: null,
			stats: new Dashboard(),
			showFilters: false,
			filters: {},
			defaultFilters: {},
		};
	},
	computed: {
		loading() {
			return !!this.stats.data.loading;
		},
		incomeEarners() {
			try {
				return this.stats.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		loadedWeek() {
			try {
				return Number(this.stats.data.response.data.meta.week_number);
			} catch (error) {
				return 0;
			}
		},
	},
	mounted() {
		this.clear();
	},
	methods: {
		getDataFiltered(filters) {
			this.stats.getTopIncomeEarners(filters).then(() => {
				this.selectedWeek = this.stats.data.response.data.meta.week_number;
			});
			this.showFilters = false;
		},
		clear() {
			this.getDataFiltered(this.defaultFilters);
			this.showFilters = false;
		},
	},
};
</script>
