<template>
	<div>
		<div
			v-if="!!Object.keys(usersInfoData).length"
			class="row mb-lg-4">
			<div
				class="col-12 col-md-4 col-lg-4 pr-md-0 pr-lg-0 pointer"
				@click="$router.push({ name: 'Users', query: {status:'active'}});">
				<users
					:small-label="translate('active_distributors')"
					:big-label="validateTotal(usersInfoData.distributor.status.active)" />
			</div>
			<div
				class="col-12 col-md-4 col-lg-4 px-md-0 px-lg-0 pointer"
				@click="$router.push({ name: 'Users', query: {status:'inactive'}});">
				<users
					:small-label="translate('inactive_distributors')"
					:big-label="validateTotal(usersInfoData.distributor.status.inactive)" />
			</div>
			<div
				:id="`popover-suspended`"
				class="col-12 col-md-4 col-lg-4 pl-md-0 pl-lg-0">
				<b-popover
					:target="`popover-suspended`"
					triggers="hover"
					custom-class="drb-pbv-popover"
					placement="top">
					<div class="p-1 pb-2">
						<template>
							<div class="pbv-popover">
								<div class="table-responsive mb-0">
									<table class="table text-nowrap">
										<thead>
											<tr class="text-center">
												<th class="p-2 text-left">
													{{ translate('status') }}
												</th>
												<th
													class="p-2 text-center">
													{{ translate('total_distributors') }}
												</th>
											</tr>
										</thead>
										<tbody>
											<tr
												v-for="item in suspendedStatuses"
												:key="item">
												<td class="p-2 align-middle text-left">
													{{ translate(item) }}
												</td>
												<td
													class="p-2 align-middle text-center"
													:class="getTotals(item) ? 'pointer drb-pbv-popover-trigger btn-link font-italic' : ''"
													@click="$router.push({ name: 'Users', query: {status: item}});">
													{{ getTotals(item) }}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</template>
					</div>
				</b-popover>
				<users
					:small-label="translate('suspended_distributors')"
					:big-label="validateTotal(usersInfoData.distributor.status.suspended_sum.toString())" />
			</div>
		</div>
		<div
			v-if="!!Object.keys(usersInfoData).length"
			class="row mb-lg-4">
			<div
				class="col-12 col-md-4 col-lg-4 pr-md-0 pr-lg-0 pointer"
				@click="$router.push({ name: 'Customers', query: {status:'active'}});">
				<users
					:small-label="translate('active_customers')"
					:big-label="validateTotal(usersInfoData.customers.status.active)" />
			</div>
			<div
				class="col-12 col-md-4 col-lg-4 px-md-0 px-lg-0 pointer"
				@click="$router.push({ name: 'Customers', query: {status:'suspended'}});">
				<users
					:small-label="translate('suspended_customers')"
					:big-label="validateTotal(usersInfoData.customers.status.suspended)" />
			</div>
			<div class="col-12 col-md-4 col-lg-4 pl-md-0 pl-lg-0">
				<users
					:small-label="translate('total_guests')"
					:big-label="validateTotal(usersInfoData.guest.total)" />
			</div>
		</div>
		<div
			v-if="!!Object.keys(usersInfoData).length"
			class="row">
			<div
				class="col-12 col-md-4 col-lg-4 pr-md-0 pr-lg-0 pointer"
				@click="$router.push({ name: 'Users'});">
				<users
					:small-label="translate('total_distributors')"
					:big-label="validateTotal(usersInfoData.total_distributors)" />
			</div>
			<div
				class="col-12 col-md-4 col-lg-4 px-md-0 px-lg-0 pointer"
				@click="$router.push({ name: 'Customers'});">
				<users
					:small-label="translate('total_customers')"
					:big-label="validateTotal(usersInfoData.customers.total)" />
			</div>
			<div
				class="col-12 col-md-4 col-lg-4 pl-md-0 pl-lg-0 pointer"
				@click="$router.push({ name: 'Corporates'});">
				<users
					:small-label="translate('total_corporates')"
					:big-label="validateTotal(usersInfoData.corporate.total)" />
			</div>
		</div>
	</div>
</template>

<script>

import { Dashboard as DashboardTranslations } from '@/translations';
import Dashboard from '@/util/Dashboard';
import Users from '@/components/Cards/CardWidgetBasic';
import { DASHBOARD_SUSPENDED_STATUSES } from '@/settings/Statuses';

export default {
	name: 'UsersTotalInfo',
	messages: [DashboardTranslations],
	components: {
		Users,
	},
	data() {
		return {
			userInfo: new Dashboard(),
			suspendedStatuses: DASHBOARD_SUSPENDED_STATUSES,
		};
	},
	computed: {
		usersInfoData() {
			try {
				const { response } = this.userInfo.data.response.data;
				return response;
			} catch (error) {
				return {};
			}
		},
		resignedDistributors() {
			try {
				return this.usersInfoData.distributor.status.resigned;
			} catch (error) {
				return 0;
			}
		},
		terminatedDistributors() {
			try {
				return this.usersInfoData.distributor.status.terminated;
			} catch (error) {
				return 0;
			}
		},
		suspendedDistributors() {
			try {
				return this.usersInfoData.distributor.status.suspended;
			} catch (error) {
				return 0;
			}
		},
	},
	mounted() {
		this.userInfo.getGlobalKpis();
	},
	methods: {
		validateTotal(total) {
			return total || '0';
		},
		getTotals(status) {
			let totals = 0;
			switch (status) {
			case 'resigned':
				totals = this.resignedDistributors;
				break;
			case 'terminated':
				totals = this.terminatedDistributors;
				break;
			default:
				totals = this.suspendedDistributors;
				break;
			}
			return totals;
		},
	},
};
</script>
