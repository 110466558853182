<template>
	<div
		v-if="!!registrationsData.length"
		class="h-100">
		<div class="row h-100">
			<div class="col mx-auto">
				<div class="card mb-0 h-100">
					<div class="card-body">
						<h5>{{ translate('new_registrations') }}</h5>
						<line-chart
							:data-import="[registrationsData, customersData, affiliatesData]"
							:x-axis-label="translate(type)"
							:y-axis-label="translate('registrations')"
							:pop-over-label="[translate('total_distributors'), translate('total_customers'), translate('total_affiliates')]"
							:show-month-label="type === 'months' ? true : false"
							class="pt-3 graphjs" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import LineChart from '@/components/LineChart.js';
import { Dashboard as DashboardTranslations } from '@/translations';
import Dashboard from '@/util/Dashboard';

export default {
	name: 'NewRegistrationsGraph',
	messages: [DashboardTranslations],
	components: {
		LineChart,
	},
	data() {
		return {
			registrations: new Dashboard(),
		};
	},
	computed: {
		registrationsData() {
			try {
				const response = [];
				const { data } = this.registrations.data.response.data.response;
				data.forEach((item) => {
					const { code, year, distributors } = item;
					response.push({ code: code.toLowerCase(), year, total: distributors });
				});
				return response;
			} catch (error) {
				return [];
			}
		},
		customersData() {
			try {
				const response = [];
				const { data } = this.registrations.data.response.data.response;
				data.forEach((item) => {
					const { code, year, customers } = item;
					response.push({ code: code.toLowerCase(), year, total: customers });
				});
				return response;
			} catch (error) {
				return [];
			}
		},
		affiliatesData() {
			try {
				const response = [];
				const { data } = this.registrations.data.response.data.response;
				data.forEach((item) => {
					const { code, year, affiliates } = item;
					response.push({ code: code.toLowerCase(), year, total: affiliates });
				});
				return response;
			} catch (error) {
				return [];
			}
		},
		type() {
			try {
				const { type } = this.registrations.data.response.data.response;
				return type;
			} catch (error) {
				return [];
			}
		},
	},
	mounted() {
		this.registrations.getNewRegistrations();
	},
};
</script>
