<template>
	<div v-if="!!Object.keys(organizationPackagesData).length">
		<div class="row h-100">
			<div class="col">
				<div class="card mb-0 h-100">
					<div class="card-header bg-dark">
						<h5 class="mb-0">
							{{ translate('organization_packages', { country: translate(showSelectedCountry.toLowerCase()) }) }}
						</h5>
					</div>
					<div class="card-body p-0 h-100">
						<div v-show="!loading">
							<div class="row no-gutters">
								<div class="col">
									<div class="row my-2 mx-1">
										<div class="col d-flex align-items-center justify-content-between">
											<h6 class="text-capitalize mb-0">
												{{ datePeriod }}
											</h6>
											<b-button
												:variant="!showFilters ? 'primary' : 'secondary'"
												class="btn btn-sm bg-primary-alt"
												:class="showFilters ? 'active' : ''"
												@click="showFilters = !showFilters">
												<i
													class="fa"
													:class="!showFilters ? 'fa-filter' : 'fa-times'"
													aria-hidden="true" />
											</b-button>
										</div>
									</div>
								</div>
							</div>
							<div class="row no-gutters">
								<div class="col">
									<div class="row no-gutters">
										<div class="col">
											<div class="table-responsive h-100 mb-0 rounded-0">
												<table class="table table-hover table-striped h-100 text-nowrap">
													<thead>
														<tr class="bg-white">
															<th class="border-top-0 align-middle text-dark">
																{{ translate('package') }}
															</th>
															<th class="border-top-0 align-middle text-dark text-center">
																{{ translate('backoffice') }}
															</th>
															<th class="border-top-0 align-middle text-dark text-center">
																{{ translate('mobile_app') }}
															</th>
															<th class="border-top-0 align-middle text-dark text-center">
																{{ translate('total') }}
															</th>
															<th class="border-top-0 align-middle text-dark text-center">
																{{ translate('percentage_sign') }}
															</th>
														</tr>
													</thead>
													<tbody>
														<tr
															v-for="(item, index) in organizationMembershipsData"
															:key="`membership-${index}`">
															<td class="align-middle">
																{{ translate(item.code_name) }}
															</td>
															<td class="align-middle text-center">
																{{ item.backoffice }}
															</td>
															<td class="align-middle text-center">
																{{ item.mobile_app }}
															</td>
															<td class="align-middle text-center">
																{{ item.total }}
															</td>
															<td class="align-middle text-center">
																{{ translate('hyphen_sign') }}
															</td>
														</tr>
														<tr class="border border-dark">
															<td
																v-for="n in 5"
																:key="`filler-${n}`"
																class="bg-dark p-0">
																<div style="height: 0.3em;" />
															</td>
														</tr>
														<tr
															v-for="(item, index) in organizationCustomersData"
															:key="`customer-${index}`">
															<td class="align-middle">
																{{ translate(item.code_name) }}
															</td>
															<td class="align-middle text-center">
																{{ item.backoffice }}
															</td>
															<td class="align-middle text-center">
																{{ item.mobile_app }}
															</td>
															<td class="align-middle text-center">
																{{ item.total }}
															</td>
															<td class="align-middle text-center">
																{{ translate('hyphen_sign') }}
															</td>
														</tr>
														<tr class="border border-dark">
															<td
																v-for="n in 5"
																:key="`filler-${n}`"
																class="bg-dark p-0">
																<div style="height: 0.3em;" />
															</td>
														</tr>
														<tr
															v-for="(item, index) in organizationPackagesData"
															:key="`package-${index}`">
															<td class="align-middle">
																{{ translate(item.code_name) }}
															</td>
															<td class="align-middle text-center">
																{{ item.backoffice }}
															</td>
															<td class="align-middle text-center">
																{{ item.mobile_app }}
															</td>
															<td class="align-middle text-center">
																{{ item.total }}
															</td>
															<td class="align-middle text-center">
																{{ averageTotal(item) }}
															</td>
														</tr>
													</tbody>
													<tfoot>
														<tr>
															<th class="align-middle">
																{{ translate('total') }}
															</th>
															<th class="align-middle text-center">
																{{ totals.backoffice }}
															</th>
															<th class="align-middle text-center">
																{{ totals.mobile_app }}
															</th>
															<th class="align-middle text-center">
																{{ totals.total }}
															</th>
															<th class="align-middle text-center">
																{{ translate('percentage_value', { amount: totals.package_totals_avg }) }}
															</th>
														</tr>
													</tfoot>
												</table>
											</div>
										</div>
										<overlay-card v-show="showFilters">
											<data-filter
												display
												get-by="name"
												form-id="organization-packages-filter"
												@submit="getDataFiltered"
												@clear="clear">
												<template slot="form">
													<div class="row">
														<div :class="dateRange === dateRanges.custom ? 'col-md-4 col-sm-6 col-xs-12' : 'col' ">
															<div class="form-group">
																<label
																	for="dateRange"
																	class="label">
																	{{ translate('date_range') }}
																</label>
																<select
																	v-model="dateRange"
																	name="dateRange"
																	class="form-control">
																	<option
																		v-for="range in dateRanges"
																		:key="range"
																		:value="range">
																		{{ translate(range) }}
																	</option>
																</select>
															</div>
														</div>
														<div
															v-if="dateRange === dateRanges.custom"
															class="col-md-4 col-sm-6 col-xs-12">
															<div class="form-group">
																<label
																	for="startDate"
																	class="label">
																	{{ translate('start_date') }}
																</label>
																<v-date-picker
																	id="startDate"
																	v-model="startDate"
																	:input-props="{ class: 'form-control' }"
																	:formats="calendarConfig"
																	name="startDate"
																	show-caps />
															</div>
														</div>
														<div
															v-if="dateRange === dateRanges.custom"
															class="col-md-4 col-sm-6 col-xs-12">
															<div class="form-group">
																<label
																	for="endDate"
																	class="label">
																	{{ translate('end_date') }}
																</label>
																<v-date-picker
																	id="endDate"
																	v-model="endDate"
																	:input-props="{ class: 'form-control' }"
																	:formats="calendarConfig"
																	name="endDate"
																	show-caps />
															</div>
														</div>
														<div :class="dateRange === dateRanges.custom ? 'col-md-4 col-sm-6 col-xs-12' : 'col' ">
															<div class="form-group">
																<label
																	for="countryName"
																	class="label">{{ translate('country') }}</label>
																<select
																	id="countryName"
																	v-model="selectedCountry"
																	name="country"
																	class="form-control">
																	<option
																		:value="'all'">
																		{{ translate('all') }}
																	</option>
																	<option
																		v-for="country in countries"
																		:key="country.attributes.code"
																		:value="country.attributes.code">
																		{{ translate(country.attributes.code.toLowerCase()) }}
																	</option>
																</select>
															</div>
														</div>
													</div>
												</template>
											</data-filter>
										</overlay-card>
									</div>
								</div>
							</div>
						</div>
						<template v-if="loading">
							<div class="h-100 d-flex justify-content-center">
								<is-loading
									class="align-self-center"
									:loading-label="translate('loading')"
									:no-data-label="translate('data_not_found')"
									:loading="loading" />
							</div>
						</template>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import moment from 'moment';
import DataFilter from '@/components/DataFilter';
import OverlayCard from '@/components/OverlayCard';
import {
	COUNTRY_VAR_NAME,
} from '@/settings/Country';
import {
	custom,
	DATE_END_FILTER,
	DATE_START_FILTER,
	DATE_RANGE_FILTER,
	DATE_RANGES,
	V_CALENDAR_CONFIG as calendarConfig,
	MDY_FORMAT,
	YMD_FORMAT,
} from '@/settings/Dates';
import {
	Countries,
	Dashboard as DashboardMessages,
	Grids,
	Packages,
} from '@/translations';
import Country from '@/util/Country';
import Dashboard from '@/util/Dashboard';

const countries = new Country();

export default {
	name: 'OrganizationPackages',
	messages: [DashboardMessages, Grids, Packages, Countries],
	components: {
		DataFilter,
		OverlayCard,
	},
	data() {
		return {
			organizationPackages: new Dashboard(),
			showFilters: false,
			filters: {},
			defaultFilters: {
				[DATE_RANGE_FILTER]: DATE_RANGES.this_week,
				[COUNTRY_VAR_NAME]: 'all',
			},
			dateRange: DATE_RANGES.this_week,
			selectedCountry: 'all',
			showSelectedCountry: '',
			countries: [],
			startDate: null,
			endDate: null,
			calendarConfig,
			dateRanges: { ...DATE_RANGES, custom },
			rangeStart: '',
			rangeEnd: '',
		};
	},
	computed: {
		datePeriod() {
			return this.getDatePeriod();
		},
		loading() {
			return !!this.organizationPackages.data.loading;
		},
		organizationCustomersData() {
			try {
				return this.organizationPackages.data.response.data.response.data.customer_stats.map((packageStat) => packageStat.attributes);
			} catch (error) {
				return {};
			}
		},
		organizationMembershipsData() {
			try {
				return this.organizationPackages.data.response.data.response.data.membership_stats.map((item) => item.attributes);
			} catch (error) {
				return {};
			}
		},
		organizationPackagesData() {
			try {
				return this.organizationPackages.data.response.data.response.data.package_stats.map((packageStat) => packageStat.attributes);
			} catch (error) {
				return {};
			}
		},
		totals() {
			try {
				return this.organizationPackages.data.response.data.response.meta.package_totals;
			} catch (error) {
				return {};
			}
		},
	},
	watch: {
		startDate(newVal) {
			try {
				this.filters[DATE_START_FILTER] = null;
				if (moment(newVal).isValid()) {
					this.filters[DATE_START_FILTER] = moment(newVal).format(YMD_FORMAT);
				}
			} catch (error) {
				this.filters[DATE_START_FILTER] = null;
			}
		},
		endDate(newVal) {
			try {
				this.filters[DATE_END_FILTER] = null;
				if (moment(newVal).isValid()) {
					this.filters[DATE_END_FILTER] = moment(newVal).format(YMD_FORMAT);
				}
			} catch (error) {
				this.filters[DATE_END_FILTER] = null;
			}
		},
		dateRange(newVal) {
			this.filters[DATE_RANGE_FILTER] = newVal;
			if (newVal !== custom) {
				this.filters[DATE_START_FILTER] = null;
				this.filters[DATE_END_FILTER] = null;
			}
		},
		selectedCountry(newVal) {
			this.filters[COUNTRY_VAR_NAME] = newVal;
		},
	},
	mounted() {
		this.clear();
		countries.getCountries().then((response) => {
			this.countries = response;
		});
	},
	methods: {
		getDataFiltered(filters) {
			const cleanedFilters = Object.fromEntries(Object.entries(filters).filter(([, value]) => value !== null));
			if (cleanedFilters.dateRange !== custom) {
				this.filters = cleanedFilters;
			}
			this.organizationPackages.getOrganizationPackages(this.filters).then((response) => {
				const { start, end } = response.response.meta.period_dates;
				this.rangeStart = this.$moment(start.date).format(MDY_FORMAT);
				this.rangeEnd = this.$moment(end.date).format(MDY_FORMAT);
				this.showSelectedCountry = this.selectedCountry;
			});
			this.showFilters = false;
		},
		clear() {
			this.filters = {};
			this.dateRange = DATE_RANGES.this_week;
			this.selectedCountry = 'all';
			this.startDate = null;
			this.endDate = null;
			this.showSelectedCountry = '';

			this.getDataFiltered(this.defaultFilters);
			this.showFilters = false;
		},
		getDatePeriod() {
			const start = this.rangeStart;
			const end = this.rangeEnd;
			if (start !== '' && end !== '') {
				return this.translate('date_period', { start, end });
			}

			if (start !== '') {
				return this.translate('date_period_from', { start });
			}

			if (end !== '') {
				return this.translate('date_period_until', { end });
			}
			return '';
		},
		averageTotal(item) {
			const { avg_total: avgTotal } = item;
			return this.translate('percentage_value', { amount: avgTotal.avg_total });
		},
	},
};
</script>
