<template>
	<div class="row no-gutters overlay-card">
		<div class="col">
			<div class="card border-light mb-3">
				<div class="card-body">
					<slot />
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'OverlayCard',
};
</script>
<style scoped>
.overlay-card {
	position: absolute;
	z-index: 5;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	margin-left: 0;
	margin-right: 0;
}
</style>
