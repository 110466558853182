<template>
	<div>
		<b-row no-gutters>
			<div class="border rounded p-2 mb-4 col-12">
				<div class="row mt-3 mb-2">
					<div class="col">
						<period-totals-info :options="options" />
					</div>
				</div>
			</div>
			<div
				v-if="showRegionalSalesMap"
				:class="['xs','sm'].includes(windowWidth) ? 'mb-4 h-50' : 'h-100'"
				class="col-md-6 mb-4 pr-2">
				<regional-sales-map :show-legend="!['xs','sm'].includes(windowWidth)" />
			</div>
			<div
				v-if="loadedData"
				:class="{
					'mb-4 h-50': ['xs','sm'].includes(windowWidth),
					'h-100': !['xs','sm'].includes(windowWidth),
					'col-md-6': showRegionalSalesMap,
					'col-12': !showRegionalSalesMap,
					'pl-2': showRegionalSalesMap,
				}"
				class=" mb-4">
				<country-sales-map
					:show-legend="!['xs','sm'].includes(windowWidth)" />
			</div>
		</b-row>
		<div class="row mb-4">
			<div class="col">
				<top-users :count="topCount" />
			</div>
		</div>
		<div class="row mb-4">
			<div class="col">
				<income-totals-graph />
			</div>
		</div>
		<div
			class="row mb-4">
			<div
				:class="{'mb-4' : ['xs','sm'].includes(windowWidth)}"
				class="col-md-5">
				<organization-ranks class="h-100" />
			</div>
			<div class="col-md-7">
				<organization-packages class="h-100" />
			</div>
		</div>
		<div class="row mb-4">
			<div class="col-12">
				<new-registrations-graph />
			</div>
		</div>
		<div class="row mb-4">
			<div class="col">
				<user-totals-info />
			</div>
		</div>
	</div>
</template>
<script>
import {
	DATE_RANGES as dateRanges,
	V_CALENDAR_CONFIG as calendarConfig,
} from '@/settings/Dates';
import {
	ADMIN_DASHBOARD_TOP_USERS_COUNT as topCount,
	SALES_BY_STATE_VALID_CORPORATE_COUNTRY as validCorporatesState,
} from '@/settings/Dashboard';
import { Grids } from '@/translations';
import WindowSizes from '@/mixins/WindowSizes';
import FiltersParams from '@/mixins/FiltersParams';
// import DataFilter from '@/components/DataFilter';
import PeriodTotalsInfo from './components/PeriodTotalsInfo';
import UserTotalsInfo from './components/UserTotalsInfo';
import IncomeTotalsGraph from './components/IncomeTotalsGraph';
import NewRegistrationsGraph from './components/NewRegistrationsGraph';
import OrganizationPackages from './components/OrganizationPackages';
import OrganizationRanks from './components/OrganizationRanks';
import CountrySalesMap from './components/CountrySalesMap';
import RegionalSalesMap from './components/RegionalSalesMap';
import TopUsers from './components/TopUsers';
import Dashboard from '@/util/Dashboard';

export default {
	name: 'AdminDashboard',
	messages: [Grids],
	components: {
		TopUsers,
		PeriodTotalsInfo,
		UserTotalsInfo,
		IncomeTotalsGraph,
		NewRegistrationsGraph,
		OrganizationPackages,
		OrganizationRanks,
		CountrySalesMap,
		RegionalSalesMap,
		// DataFilter,
	},
	mixins: [FiltersParams, WindowSizes],
	data() {
		return {
			calendarConfig,
			dateRanges,
			options: {},
			rangeStart: '',
			rangeEnd: '',
			topCount,
			validCorporatesState,
			dashboard: new Dashboard(),
			showRegionalSalesMap: false,
			loadedData: false,
		};
	},
	computed: {
		datePeriod() {
			const start = this.rangeStart;
			const end = this.rangeEnd;
			if (start !== '' && end !== '') {
				return this.translate('date_period', { start, end });
			}

			if (start !== '') {
				return this.translate('date_period_from', { start });
			}

			if (end !== '') {
				return this.translate('date_period_until', { end });
			}
			return '';
		},
		showAllCountries() {
			return this.$user.details().country === undefined;
		},
	},
	mounted() {
		this.dashboard.showRegionalSales().then((response) => {
			this.showRegionalSalesMap = response.show_regional_sales;
		}).finally(() => {
			this.loadedData = true;
		});
	},
	methods: {
		getDataFiltered() {
			const { query } = this.$route;
			this.options = { ...query, ...this.filters };
		},
	},
};
</script>
