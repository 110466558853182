<template>
	<div class="card bg-dark rounded-0 h-100 m-0">
		<div class="card-body clearfix py-2">
			<div class="h4 text-primary mb-0 pt-2">
				{{ bigLabel }}
			</div>
			<div class="text-light text-uppercase font-weight-bold font-xs">
				{{ smallLabel }}
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'CardWidgetBasic',
	props: {
		bigLabel: {
			type: String,
			default: '',
		},
		smallLabel: {
			type: String,
			default: '',
		},
	},
	data() {
		return {};
	},
	methods: {},
};
</script>

<style>
	.opacity-4{
		opacity: 0.4;
	}
</style>
