<template>
	<div :class="['xs', 'sm'].includes(windowWidth) ? 'mb-3' : ''">
		<div class="row no-gutters">
			<div class="col">
				<p :class="['xs', 'sm', 'md'].includes(windowWidth) ? 'h6' : 'h5'">
					{{ title }}
				</p>
			</div>
		</div>
		<div class="row no-gutters">
			<div class="col position-relative">
				<div class="table-responsive h-100 mb-0">
					<table
						v-if="userData.length !== 0"
						class="table table-hover table-striped h-100">
						<tbody>
							<tr
								v-for="(user, index) in userData"
								:key="index">
								<td
									class="align-middle text-right px-1"
									style="width: 40px;">
									<template v-if="user">
										<span
											v-if="user.type === distributor"
											class="badge badge-primary pointer"
											@click="toDashboard({ distributorId: user.id.toString() })">
											{{ user.id }}
										</span>
										<span
											v-else
											class="badge badge-primary">
											{{ user.id }}
										</span>
									</template>
								</td>
								<td class="align-middle ml-2 px-1 d-md-none d-lg-table-cell text-left">
									<template v-if="user">
										{{ user.first_name }}
									</template>
								</td>
								<td class="align-middle pl-1 text-right">
									<template v-if="user">
										{{ user.total }}
									</template>
								</td>
							</tr>
						</tbody>
					</table>
					<is-loading
						v-if="userData.length === 0"
						:has-data="userData.length !== 0"
						:loading-label="translate('loading')"
						:no-data-label="translate('data_not_found')" />
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import DashboardRedirect from '@/mixins/DashboardRedirect';
import WindowSizes from '@/mixins/WindowSizes';
import { TopUsers } from '@/translations';
import { distributor } from '@/settings/Roles';

export default {
	name: 'UserList',
	messages: [TopUsers],
	mixins: [DashboardRedirect, WindowSizes],
	props: {
		title: {
			type: String,
			default: '',
		},
		users: {
			type: Array,
			default: () => [],
		},
		count: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			distributor,
		};
	},
	computed: {
		userData() {
			if (!['xs', 'sm'].includes(this.windowWidth)) {
				const filledArray = new Array(this.count).fill(false);
				filledArray.forEach((value, index) => {
					if (this.users[index]) {
						filledArray[index] = this.transformUser(this.users[index]);
					}
				});
				return filledArray;
			}
			return this.users.map((user) => this.transformUser(user));
		},
	},
	methods: {
		transformUser(user) {
			return {
				id: user.id,
				first_name: user.attributes.first_name,
				last_name: user.attributes.last_name,
				total: user.attributes.total,
				type: user.attributes.user_type,
			};
		},
	},
};
</script>
<style scoped>
td {
	height: 46px;
}
</style>
