<template>
	<div class="h-100">
		<div class="row h-100">
			<div class="col mx-auto h-100">
				<div class="card rounded-0 mb-0 h-100">
					<div class="card-body rounded-0">
						<div v-show="!loading">
							<div class="row mb-2">
								<div class="col">
									<h6 class="text-capitalize float-left">
										{{ datePeriod }}
									</h6>
									<b-button
										:variant="!showFilters ? 'primary' : 'secondary'"
										class="float-right btn btn-sm bg-primary-alt"
										:class="showFilters ? 'active' : ''"
										@click="showFilters = !showFilters">
										<i
											class="fa"
											:class="!showFilters ? 'fa-filter' : 'fa-times'"
											aria-hidden="true" />
									</b-button>
								</div>
							</div>
							<div class="row">
								<div class="col">
									<div class="row">
										<div class="col-12 col-md-4">
											<user-list
												:title="translate('volume_producers')"
												:users="volumeProducers"
												:count="count" />
										</div>
										<div class="col-12 col-md-4">
											<user-list
												:title="translate('recruiters')"
												:users="recruiters"
												:count="count" />
										</div>
										<div class="col-12 col-md-4">
											<user-list
												:title="translate('retailers')"
												:users="retailers"
												:count="count" />
										</div>
									</div>
									<overlay-card v-show="showFilters">
										<data-filter
											display
											get-by="name"
											form-id="top-users-filter"
											@submit="getDataFiltered"
											@clear="clear">
											<template slot="form">
												<div class="row">
													<div :class="dateRange === dateRanges.custom ? 'col-md-4 col-sm-6 col-xs-12' : 'col' ">
														<div class="form-group">
															<label
																for="dateRange"
																class="label">
																{{ translate('date_range') }}
															</label>
															<select
																v-model="dateRange"
																name="dateRange"
																class="form-control">
																<option
																	v-for="range in dateRanges"
																	:key="range"
																	:value="range">
																	{{ translate(range) }}
																</option>
															</select>
														</div>
													</div>
													<div
														v-if="dateRange === dateRanges.custom"
														class="col-md-4 col-sm-6 col-xs-12">
														<div class="form-group">
															<label
																for="startDate"
																class="label">
																{{ translate('start_date') }}
															</label>
															<v-date-picker
																id="startDate"
																v-model="startDate"
																:input-props="{ class: 'form-control' }"
																:formats="calendarConfig"
																name="startDate"
																show-caps />
														</div>
													</div>
													<div
														v-if="dateRange === dateRanges.custom"
														class="col-md-4 col-sm-6 col-xs-12">
														<div class="form-group">
															<label
																for="endDate"
																class="label">
																{{ translate('end_date') }}
															</label>
															<v-date-picker
																id="endDate"
																v-model="endDate"
																:input-props="{ class: 'form-control' }"
																:formats="calendarConfig"
																name="endDate"
																show-caps />
														</div>
													</div>
												</div>
											</template>
										</data-filter>
									</overlay-card>
								</div>
							</div>
						</div>
						<template v-if="loading">
							<div class="h-100 d-flex justify-content-center">
								<is-loading
									class="align-self-center"
									:loading-label="translate('loading')"
									:no-data-label="translate('data_not_found')"
									:loading="loading" />
							</div>
						</template>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import moment from 'moment';
import DataFilter from '@/components/DataFilter';
import OverlayCard from '@/components/OverlayCard';
import {
	custom,
	DATE_END_FILTER,
	DATE_START_FILTER,
	DATE_RANGE_FILTER,
	DATE_RANGES,
	V_CALENDAR_CONFIG as calendarConfig,
	MDY_FORMAT,
	YMD_FORMAT,
} from '@/settings/Dates';
import { Dashboard as DashboardMessages, Report, TopUsers } from '@/translations';
import Dashboard from '@/util/Dashboard';
import UserList from './UserList';

export default {
	name: 'TopUsersStats',
	messages: [DashboardMessages, Report, TopUsers],
	components: {
		DataFilter,
		OverlayCard,
		UserList,
	},
	props: {
		count: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			stats: new Dashboard(),
			showFilters: false,
			filters: {},
			defaultFilters: {
				[DATE_RANGE_FILTER]: DATE_RANGES.this_week,
			},
			dateRange: DATE_RANGES.this_week,
			startDate: null,
			endDate: null,
			calendarConfig,
			dateRanges: { ...DATE_RANGES, custom },
			rangeStart: '',
			rangeEnd: '',
		};
	},
	computed: {
		datePeriod() {
			return this.getDatePeriod();
		},
		loading() {
			return !!this.stats.data.loading;
		},
		volumeProducers() {
			try {
				return this.stats.data.response.data.data.volume_producers;
			} catch (error) {
				return [];
			}
		},
		recruiters() {
			try {
				return this.stats.data.response.data.data.recruiters;
			} catch (error) {
				return [];
			}
		},
		retailers() {
			try {
				return this.stats.data.response.data.data.retailers;
			} catch (error) {
				return [];
			}
		},
	},
	watch: {
		startDate(newVal) {
			try {
				this.filters[DATE_START_FILTER] = null;
				if (moment(newVal).isValid()) {
					this.filters[DATE_START_FILTER] = moment(newVal).format(YMD_FORMAT);
				}
			} catch (error) {
				this.filters[DATE_START_FILTER] = null;
			}
		},
		endDate(newVal) {
			try {
				this.filters[DATE_END_FILTER] = null;
				if (moment(newVal).isValid()) {
					this.filters[DATE_END_FILTER] = moment(newVal).format(YMD_FORMAT);
				}
			} catch (error) {
				this.filters[DATE_END_FILTER] = null;
			}
		},
		dateRange(newVal) {
			this.filters[DATE_RANGE_FILTER] = newVal;
			if (newVal !== custom) {
				this.filters[DATE_START_FILTER] = null;
				this.filters[DATE_END_FILTER] = null;
			}
		},
	},
	mounted() {
		this.clear();
	},
	methods: {
		getDataFiltered(filters) {
			const cleanedFilters = Object.fromEntries(Object.entries(filters).filter(([, value]) => value !== null));
			if (cleanedFilters.dateRange !== custom) {
				this.filters = cleanedFilters;
			}
			this.stats.getTopUserStats(this.filters).then(() => {
				const { start, end } = this.stats.data.response.data.meta.period_dates;
				this.rangeStart = this.$moment(start.date).format(MDY_FORMAT);
				this.rangeEnd = this.$moment(end.date).format(MDY_FORMAT);
			});
			this.showFilters = false;
		},
		clear() {
			this.filters = {};
			this.dateRange = DATE_RANGES.this_week;
			this.startDate = null;
			this.endDate = null;

			this.getDataFiltered(this.defaultFilters);
			this.showFilters = false;
		},
		getDatePeriod() {
			const start = this.rangeStart;
			const end = this.rangeEnd;
			if (start !== '' && end !== '') {
				return this.translate('date_period', { start, end });
			}

			if (start !== '') {
				return this.translate('date_period_from', { start });
			}

			if (end !== '') {
				return this.translate('date_period_until', { end });
			}
			return '';
		},
	},
};
</script>
