<template>
	<div class="h-100">
		<div class="row h-100 no-gutters">
			<div class="col mx-auto h-100">
				<div class="card mb-0 p-0 h-100">
					<div class="card-header bg-dark">
						<h5 class="mb-0">
							{{ translate('top_users') }}
						</h5>
					</div>
					<div class="card-body p-0">
						<div class="row no-gutters">
							<div class="col-md-3">
								<top-income-earners :count="count" />
							</div>
							<div class="col-md-9">
								<top-users-stats :count="count" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';
import { TopUsers } from '@/translations';
import TopIncomeEarners from './IncomeEarners';
import TopUsersStats from './Stats';

export default {
	name: 'TopUsers',
	messages: [TopUsers],
	components: {
		TopIncomeEarners,
		TopUsersStats,
	},
	mixins: [WindowSizes],
	props: {
		count: {
			type: Number,
			default: 0,
		},
	},
};
</script>
