<template>
	<data-filter
		display
		get-by="name"
		:form-id="formId"
		@submit="emitValues"
		@clear="clearFilter">
		<template slot="form">
			<div class="row no-gutters">
				<div class="col">
					<div class="form-group">
						<label
							for="week"
							class="label">
							{{ translate('week') }}
						</label>
						<select
							v-model="selectedWeek"
							name="week"
							class="form-control">
							<option
								v-for="week in weeks"
								:key="week.attributes.number"
								:value="week.attributes.number">
								{{ translate('week_number', { number: week.attributes.number }) }}
							</option>
							<option
								v-if="weeks.length === 0"
								:value="loadedWeek">
								{{ translate('week_number', { number: loadedWeek }) }}
							</option>
						</select>
					</div>
				</div>
			</div>
		</template>
	</data-filter>
</template>
<script>
import DataFilter from '@/components/DataFilter';
import Weeks from '@/util/Weeks';

export default {
	name: 'WeekFilter',
	components: {
		DataFilter,
	},
	props: {
		loadedWeek: {
			type: Number,
			default: 1,
		},
		formId: {
			type: String,
			required: true,
			default: '',
		},
	},
	data() {
		return {
			weeksInfo: new Weeks(),
			selectedWeek: null,
		};
	},
	computed: {
		weeks() {
			try {
				const { data } = this.weeksInfo.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
	},
	watch: {
		loadedWeek() {
			this.setSelectedWeek();
		},
	},
	mounted() {
		this.weeksInfo.getWeeks().then(() => {
			this.setSelectedWeek();
		});
	},
	methods: {
		emitValues() {
			this.$emit('submit', { week_number: this.selectedWeek });
		},
		clearFilter() {
			this.$emit('clear');
			this.setSelectedWeek();
		},
		setSelectedWeek() {
			this.selectedWeek = this.loadedWeek;
		},
	},
};
</script>
<style scoped>
select, select option {
	text-transform: capitalize;
}
</style>
